import * as client_hooks from '../../../src/hooks.client.ts';


export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14'),
	() => import('./nodes/15'),
	() => import('./nodes/16'),
	() => import('./nodes/17'),
	() => import('./nodes/18'),
	() => import('./nodes/19'),
	() => import('./nodes/20'),
	() => import('./nodes/21'),
	() => import('./nodes/22'),
	() => import('./nodes/23'),
	() => import('./nodes/24'),
	() => import('./nodes/25'),
	() => import('./nodes/26'),
	() => import('./nodes/27'),
	() => import('./nodes/28'),
	() => import('./nodes/29'),
	() => import('./nodes/30'),
	() => import('./nodes/31'),
	() => import('./nodes/32'),
	() => import('./nodes/33'),
	() => import('./nodes/34'),
	() => import('./nodes/35'),
	() => import('./nodes/36'),
	() => import('./nodes/37'),
	() => import('./nodes/38'),
	() => import('./nodes/39'),
	() => import('./nodes/40'),
	() => import('./nodes/41'),
	() => import('./nodes/42'),
	() => import('./nodes/43'),
	() => import('./nodes/44'),
	() => import('./nodes/45'),
	() => import('./nodes/46'),
	() => import('./nodes/47'),
	() => import('./nodes/48'),
	() => import('./nodes/49'),
	() => import('./nodes/50'),
	() => import('./nodes/51')
];

export const server_loads = [0,9,4,6,8];

export const dictionary = {
		"/(app)": [~10,[2],[3]],
		"/(app)/cambiar-contrasena/[token]": [11,[2],[3]],
		"/(app)/como-participar": [12,[2],[3]],
		"/(app)/como-vender": [13,[2],[3]],
		"/(app)/contacto": [14,[2],[3]],
		"/dashboard/(v1)": [~42,[9]],
		"/dashboard/(v1)/auctions": [~43,[9]],
		"/dashboard/(v1)/auctions/live": [~45,[9]],
		"/dashboard/(v1)/auctions/[auctionId]/lots": [~44,[9]],
		"/dashboard/(v1)/settings": [~46,[9]],
		"/dashboard/(v1)/users": [~47,[9]],
		"/dashboard/(v1)/vendors": [~48,[9]],
		"/dashboard/(v2)/[entity]": [~49,[9]],
		"/forbidden": [50],
		"/(app)/ingresar": [~15,[2],[3]],
		"/(app)/inmuebles": [~16,[2],[3]],
		"/(app)/lotes/[slug]/[id]": [~17,[2],[3]],
		"/maintenance": [~51],
		"/(app)/mis-lotes": [18,[2],[3]],
		"/(app)/olvidaste-tu-contrasena": [19,[2],[3]],
		"/(app)/perfil": [~20,[2,4],[3]],
		"/(app)/perfil/cambiar-contrasena": [21,[2,4],[3]],
		"/(app)/perfil/passkey": [22,[2,4],[3]],
		"/(app)/preguntas-frecuentes": [23,[2,5],[3]],
		"/(app)/quienes-somos": [24,[2],[3]],
		"/(app)/registrarse": [~25,[2],[3]],
		"/(app)/reportes": [~26,[2,6],[3]],
		"/(app)/reportes/diario": [~27,[2,6],[3]],
		"/(app)/reportes/mensual": [~28,[2,6],[3]],
		"/(app)/reportes/subastas/[auctionId]": [~29,[2,6],[3]],
		"/(app)/subastas": [~30,[2],[3]],
		"/(app)/subastas/anteriores": [~32,[2],[3]],
		"/(app)/subastas/[slug]/[id]": [~31,[2],[3]],
		"/(app)/terminos-y-condiciones-de-uso": [33,[2,7],[3]],
		"/(app)/vendedor/registrarse": [34,[2],[3]],
		"/(app)/verificar-correo/[code]": [35,[2],[3]],
		"/(app)/workflows": [~36,[2,8],[3]],
		"/(app)/workflows/delivery-pending": [~37,[2,8],[3]],
		"/(app)/workflows/list": [~38,[2,8],[3]],
		"/(app)/workflows/payment-verification": [~39,[2,8],[3]],
		"/(app)/workflows/pending-payments": [~40,[2,8],[3]],
		"/(app)/workflows/protocols": [~41,[2,8],[3]]
	};

export const hooks = {
	handleError: client_hooks.handleError || (({ error }) => { console.error(error) }),
	init: client_hooks.init,
	reroute: (() => {}),
	transport: {}
};

export const decoders = Object.fromEntries(Object.entries(hooks.transport).map(([k, v]) => [k, v.decode]));

export const hash = false;

export const decode = (type, value) => decoders[type](value);

export { default as root } from '../root.svelte';